import React from 'react';
import { useState, useEffect } from 'react'
import formogenhet from './homesc.json'
import fonder from './fonder.json'

const Hemskarm = ({ setFond, setPage }) => {
    const [topavk, setAvkastning] = useState([]);
    const [samstavk, setSamstavk] = useState([]);

    /* function fondknapp(fond) {
        setFond(fonder[fond])
        setPage(4);
        window.history.pushState({ page: 4 }, "", ('"' + fond + '"'));
    } */

    useEffect(() => {

        const fetchData = () => {
            const avkastningList = [];

            Object.keys(formogenhet).forEach((key) => {
                const fond = formogenhet[key];

                const f1 = parseFloat(fond.Förmögenhet1);
                const f2 = parseFloat(fond.Förmögenhet2);

                if (f1 !== 0 && f2 !== 0 && !isNaN(f1) && !isNaN(f2)) {
                    const avk = ((f2 - f1) / f1) * 100;
                    avkastningList.push({
                        namn: fond.Namn,
                        avkastning: avk
                    });
                }
            });
            avkastningList.sort((a, b) => b.avkastning - a.avkastning);
            const topfem = avkastningList.slice(0, 5);
            const samstfem = avkastningList.slice(-5).reverse();
            setAvkastning(topfem);
            setSamstavk(samstfem);
        }
        fetchData();
    }, []);


    return (
        <div className='hemskarm'>
            <div className='wrapper'>
                <table className='hometable'>
                    <caption style={{ fontWeight: "bold", fontSize: "16pt", marginBottom: "20pt" }}>Top 5 fonder</caption>
                    <thead></thead>
                    <tbody>
                        {topavk.map((fond, index) => (
                            <tr key={index} className="tr" /*onClick={() => fondknapp(fond)} */>
                                <td>{index + 1}</td>
                                <td>{fond.namn}</td>
                                <td>{fond.avkastning.toFixed(2)}%</td>
                            </tr>
                        ))}

                    </tbody>
                </table>
            </div>
            <div className='wrapper'>
                <table className='hometable'>
                    <caption style={{ fontWeight: "bold", fontSize: "16pt", marginBottom: "20pt" }}>Sämst 5 fonder</caption>
                    <thead></thead>
                    <tbody>
                        {samstavk.map((fond, index) => (
                            <tr key={index} className='tr'>
                                <td>{index + 1}</td>
                                <td>{fond.namn}</td>
                                <td>{fond.avkastning.toFixed(2)}%</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div >
    );
}

export default Hemskarm;