
import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import likviditet from './likvidQ2.json'
import slutvarde from './slutvarde.json'

ChartJS.register(ArcElement, Tooltip, Legend);

const colorList = [
  "#2A1FFF",
  "#841FFF",
  "#DD1FFF",
  "#FF1FC7",
  "#FF1F6D",
  "#FF261F",
  "#FF801F",
  "#FFDA1F",
  "#CBFF1F",
  "#71FF1F",
  "#1FFF26",
  "#1FFF80",
  "#1FFFDA",
  "#1FCBFF",
  "#1F71FF"
];

function getRandomColors(count) {
  // Om det begärda antalet färger är större än listan, returnera alla färger

  const selectedColors = [];

  // Hämta 'count' antal unika färger från listan
  for (let i = count; i >= 0; i--) {
    selectedColors[i] = colorList[i % colorList.length]
  }

  return selectedColors;
}


const options = {
  segmentShowStroke: false,
  plugins: {
    legend: {
      display: false,
      position: 'bottom', // Flyttar legenden till botten (alternativ: 'top', 'left', 'right')
      align: 'start', // Justera legenden ('start', 'center', 'end')
    },

  },
};

function Fonddata({ fonder, fond, setFond }) {

  //Lägger till fonddata
  const [aktie, setAktie] = useState([])
  useEffect(() => {
    const fetchData = () => {
      const obj = fond;
      const aktier = fond.FinansiellaInstrument;
      aktier.sort((instrument1, instrument2) => parseFloat(instrument2.A) - parseFloat(instrument1.A))
      const branscher = {}
      const lander = {}
      aktier.forEach(a => {
        branscher[a.B] = (branscher[a.B] || 0) + 1;
        lander[a.L] = (lander[a.L] || 0) + 1;
      });


      obj.land = lander;
      obj.bransch = branscher;

      const bcolors = getRandomColors(Object.keys(branscher).length);
      const bpie = {
        labels: Object.keys(branscher),
        datasets: [
          {
            label: 'Branscher',
            data: Object.values(branscher),
            backgroundColor: bcolors,
            hoverBackgroundColor: bcolors,
            borderColor: 'black',
            borderWidth: 2,
          }
        ]
      }

      const lcolors = getRandomColors(Object.keys(lander).length);
      const lpie = {
        labels: Object.keys(lander),
        datasets: [
          {
            label: 'Länder',
            data: Object.values(lander),
            backgroundColor: lcolors,
            hoverBackgroundColor: lcolors,
            borderWidth: 2,
            borderColor: 'black',

          }
        ]
      }
      obj.bpie = bpie
      obj.lpie = lpie

      setFond(obj);
      setAktie(aktier)

    }
    fetchData();

  }, [fond, fonder, setFond]);


  //Lägger till aktiers avkastning
  const [procentForandring, setProcentForandring] = useState([]);
  useEffect(() => {
    const svList = [];
    Object.values(slutvarde).forEach(fnd => {
      if (fnd.FinansiellaInstrument) {
        fnd.FinansiellaInstrument.forEach(instrument => {
          const s1 = parseFloat(instrument.S1);
          const s2 = parseFloat(instrument.S2);

          if (!isNaN(s1) && !isNaN(s2) && s1 !== 0 && s2 !== 0) {
            const procentuellForandring = ((s2 - s1) / s1) * 100;
            svList.push({
              namn: instrument.N,
              s1: instrument.S1,
              andelsv: procentuellForandring.toFixed(2)
            });
          }
        });
      }
    });

    setProcentForandring(svList);
  }, []);


  //Lägger till likvida medel
  const [likvida, setLikvida] = useState([]);
  useEffect(() => {

    const fetchLikvida = () => {
      const lmList = [];
      Object.keys(likviditet).forEach((key) => {
        const likvidamedel = likviditet[key];

        const lm = parseFloat(likvidamedel.Likviditet);
        const fm = parseFloat(likvidamedel.Förmögenhet);

        if (lm !== 0 && fm !== 0 && !isNaN(lm) && !isNaN(fm)) {
          const lik = (lm / fm) * 100;
          lmList.push({
            namn: likvidamedel.Namn,
            andellm: lik
          });
        }
      });
      setLikvida(lmList);
    }
    fetchLikvida();
  }, []);



  return (

    <div style={{ display: "flex", alignItems: "flex-start", margin: "0 auto" }}>

      <div style={{ borderCollapse: "collapse", display: "flex", flexDirection: "column", margin: "20px 20px 20px auto" }}>

        {fond.ISIN && (
          <img alt="" src={"./4x3/" + (fond.ISIN || " ").slice(0, 2) + ".svg"}></img>
        )}

        {fond.Namn && (
          <>
            <p style={{ fontSize: 24, fontWeight: "bold", margin: 0, paddingBottom: 0 }}>
              {fond.Namn}
            </p>

            <p style={{ fontSize: 18 }}>
              {fond.Bolag}
            </p>

            <p style={{ fontSize: 18 }}>
              {fond.ISIN}
            </p>
          </>
        )}

        {fond.land && (
          <Doughnut data={fond.lpie} options={options} />
        )}

        {fond.land && ( // Check if fond.land is defined before rendering
          <table style={{ borderCollapse: "separate", margin: "20px auto 20px 20px", border: "2px solid #ff8000", borderRadius: "10px", textAlign: "left" }}>
            <caption><strong>Landsfördelning</strong></caption>
            <thead>
              <tr>
                <th style={{ textAlign: "right" }}>#</th>
                <th style={{ textAlign: "left" }}>Land</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(fond.land).sort(([, val1], [, val2]) => val2 - val1).map(([kod, val], index) => (
                <tr key={index}>
                  <td style={{ textAlign: "right" }}>{val}</td>
                  <td style={{ textAlign: "left" }}><img alt="" style={{ display: "block", height: "1rem", padding: "0px", margin: "0px" }} src={"./4x3/" + kod.toLowerCase() + ".svg"}></img></td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {fond.bpie && (
          <Doughnut data={fond.bpie} options={options} />
        )}

        {fond.bransch && ( // Check if fond.land is defined before rendering

          <table style={{ borderCollapse: "separate", margin: "20px auto 20px 20px", border: "2px solid #ff8000", borderRadius: "10px", textAlign: "left" }}>
            <caption><strong>Branschanalys</strong></caption>
            <thead>
              <tr>
                <th style={{ textAlign: "right" }}>#</th>
                <th style={{ textAlign: "left" }}>Bransch</th>
              </tr>
            </thead>
            <tbody style={{ textAlign: "left" }}>
              {Object.entries(fond.bransch)
                .sort(([, val1], [, val2]) => val2 - val1)
                .map(([kod, val], index) => (
                  <tr key={index}>
                    <td style={{ textAlign: "right" }}>{val}</td>
                    <td>{kod || "Övrigt"}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}


      </div>



      {fond.land && (
        <table style={{ borderCollapse: "separate", margin: "20px auto 20px 20px", border: "2px solid #ff8000", borderRadius: "10px", textAlign: "left" }}>
          <caption><strong>Underliggande instrument</strong></caption>
          <thead>
            <tr>
              <th>#</th>
              <th></th>
              <th>Namn</th>
              <th>Andel</th>
              <th style={{ borderLeft: "2px solid #ff8000", paddingLeft: "10px" }}>Avkastning</th>
            </tr>
          </thead>
          <tbody>
            {aktie.map((instrument, index) => {
              const forandring = procentForandring.find(f => f.namn === instrument.N);
              const setfarg = forandring ? forandring.andelsv : null;

              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td><img alt="" style={{ display: "block", height: "1rem" }} src={"./4x3/" + instrument.L + ".svg"}></img></td>
                  <td><strong>{instrument.N}</strong></td>
                  <td>{instrument.A + " %"}</td>
                  <td
                    style={{
                      borderLeft: "2px solid #ff8000",
                      paddingLeft: "10px",
                      color: setfarg > 0 ? "green" : setfarg < 0 ? "red" : "grey",  // Ändra färgen beroende på värdet
                    }}
                  >
                    {setfarg !== null ? setfarg + " %" : "N/A"}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}


      <table style={{ borderCollapse: "separate", margin: "20px auto 20px 20px", border: "2px solid #ff8000", borderRadius: "10px", textAlign: "left" }}>
        <caption><strong>Övrig information</strong></caption>
        <thead>
          <tr>
            <th>Namn</th>
            <th></th>
            <th>Andel</th>
          </tr>
        </thead>
        <tbody>
          {likvida
            .filter((lm) => lm.namn === fond.Namn)
            .map((lm, index) => (
              <tr key={index}>
                <td>Andel likvida medel</td>
                <td></td>
                <td>{lm.andellm.toFixed(2)}%</td>
              </tr>
            ))}
        </tbody>
      </table>


    </div>
  );
}
export default Fonddata;