import React from 'react'
import { useState, useEffect } from 'react'
import './App.css';
import Fonddata from './Fonddata'
import Insyn from './Insynshandel'
import Fonder from './Fonder'
import Header from './Header'
import Footer from './Footer'
import Hemskarm from './Hemskarm'

import fonder from './fonder.json'


function App() {
  const [page, setPage] = useState(0)
  const [fond, setFond] = useState({})


  const handleNavigation = (url) => {
    let path = url || window.location.pathname; // Använd aktuell URL om ingen url ges
    path = path.toLowerCase()
    path = path.replaceAll("/", "").replaceAll("%22", "").replaceAll("%20", " ").replaceAll("%c3%a5", "å").replaceAll("%c3%a4", "ä").replaceAll("%c3%b6", "ö").replaceAll("%c3%96", "ö").replaceAll("%c3%85", "å").replaceAll("%c3%84", "ä");

    if (path === "") {
      setPage(0);
    } else if (path === 'fonder') {
      setPage(3)
    } else if (path === 'home') {
      setPage(0)
    } else {
      setPage(4)
      setFond(fonder[path])
    }

  };

  useEffect(() => {
    window.addEventListener('popstate', () => handleNavigation());
    handleNavigation(); // Anropar funktionen för att sätta sidan vid laddning

    // Rensa eventlyssnaren när komponenten avmonteras
    return () => {
      window.removeEventListener('popstate', handleNavigation);
    };
  }, []);

  let content

  if (page === 0) {
    content = <Hemskarm />
  } else if (page === 1) {
    content = <Hemskarm />
  } else if (page === 4) {
    content = <Fonddata fonder={fonder} fond={fond} setFond={setFond} />
  } else if (page === 3) {
    content = <Fonder setFond={setFond} setPage={setPage} />
  } else if (page === 5) {
    content = <Insyn />
  }

  return (
    <div className="App">
      <Header setPage={setPage} />

      <main>
        {content}

      </main>

      <Footer />
    </div>
  );
}

export default App;
