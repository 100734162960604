import React from 'react'

function Footer() {
    return (
        <footer style={{ background: "#404040", overflow: "hidden", color: "white", width: "100%", height: "auto" }}>
        <h3>Börsgrottan</h3>
        <p>
            Börsgrottan Incorporated AB
            <br></br>
            Öppen data från Finansinspektionen
            <br></br>
            Augusti 2024
            
        </p>
        
        </footer>
    );
}

export default Footer
