import React from 'react'
import { useEffect } from 'react'



function Header({ setPage }) {
  // Navigeringsfunktioner
  function defaultClick() {
    setPage(0);
    window.history.pushState({ page: 0 }, "", "/");
  }

  function homeClick() {
    setPage(1);
    window.history.pushState({ page: 1 }, "", "/home");
  }

  function fondClick() {
    setPage(3);
    window.history.pushState({ page: 3 }, "", "/fonder");
  }

  function insynClick() {
    setPage(5);
    window.history.pushState({ page: 4 }, "", "/insyn");
  }

  // Lägg till en lyssnare för 'popstate' för att hantera tillbaka-knappen
  useEffect(() => {
    function handlePopState(event) {
      const page = event.state?.page ?? 0; // Standard till sidan 0 om ingen state finns
      setPage(page);
    }

    // Lägg till eventlyssnare när komponenten mountas
    window.addEventListener('popstate', handlePopState);

    // Ta bort eventlyssnare när komponenten avmountas
    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [setPage]); // Bara skapa eventlyssnaren om `setPage` ändras

  return (
    <div style={{ padding: "1rem", display: "flex", background: "#ff8000", overflow: "hidden" }}>
      <button
        style={{ cursor: "pointer", fontWeight: "bold", fontSize: "1.75rem", backgroundColor: "#000000", color: "#ff8000", border: "none", borderRadius: "0.5rem", padding: "0px 0.5rem" }}
        onClick={defaultClick}
      >
        Börsgrottan
      </button>

      <span id="headerspan">
        <button className="headerbutton" onClick={homeClick}>Hem</button>
        <button className="headerbutton" onClick={fondClick}>Fonder</button>
        <button className="headerbutton" onClick={insynClick}>Insynshandel</button>
      </span>
    </div>
  );
}

export default Header