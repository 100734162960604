import React from 'react';

function Insynshandel({ file }) {
    //const [aktie, setAktie] = useState([])

    return (
        <div style={{}}>
            <h1>Insynshandel</h1>
            <p><strong>Aktiens namn:</strong></p>
            <h2>Underliggande Instrument</h2>
        </div>
    );
}
export default Insynshandel;