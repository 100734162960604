import React from 'react'
import { useState, useEffect } from 'react'
import './App.css';
import fonder from './fonder.json'


function Fonder({ setFond, setPage}) {
    const [fileList, setFileList] = useState(Object.keys(fonder));
    const [text, setText] = useState("")

    function fondknapp(fond) {
      setFond(fonder[fond])
      setPage(4);
      window.history.pushState({ page: 4 }, "", ('"' + fond + '"'));
  
    }
  
    useEffect(() => {
  
      setFileList(Object.keys(fonder))
  
      function handlePopState(event) {
        const page = event.state?.page ?? 0; // Standard till sidan 0 om ingen state finns
        setPage(page);
      }
  
      // Lägg till eventlyssnare när komponenten mountas
      window.addEventListener('popstate', handlePopState);
  
      // Ta bort eventlyssnare när komponenten avmountas
      return () => {
        window.removeEventListener('popstate', handlePopState);
      };
    }, [setPage]); // Bara skapa eventlyssnaren om `setPage` ändras
  
    /* function hbclick() {
      filnamn = "Handelsbanken Svenska Småbolag_51318_VPFO_2024Q1_2024-04-09 16.07.xml";
      setPage(4);
    } */
  
      const filtrerad = fileList.filter((e) =>
        e.toLowerCase().includes(text.toLowerCase())
      );
  
    const lista = filtrerad.map(fond => (
       
      <button key={fond} className="fondknapp" onClick={() => fondknapp(fond)}>{fond}</button>
        
    ))

    
  
    return (
      <div style={{display: "flex", alignItems: "flex-start", flexDirection: "column", padding: "20px 20px"}}>

        
          <input placeholder="Sök fonder..." className="sokbar" name="fondSearch" type="text" value={text} onChange={e => setText(e.target.value)}></input>
        
        {lista}
      </div>
  
    )
  }

  export default Fonder